
export default {
  name: 'SterBanner',
  props: {
    /**
     * Adslot to load: (general) (article)
     */
    place: {
      type: String,
      validator: prop => Object.values(['general', 'article']).includes(prop),
      default: 'general',
    },
  },
  mounted () {
    this.initAd()
  },
  methods: {
    initAd () {
      // Retrieved from https://adcdn.ster.nl/script/adscript.eo.algemeen.min.js

      // Functions
      const GetViewPort = function () {
        try {
          return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        }
        catch (e) {
          return 1800
        }
      }
      
      const GetAdSizesByLabel = function (size) {
        switch (size) {
          case 'demo':
            return '301x250'
          case 'noad':
            return '302x250'
          case 'banner':
            return '468x60'
          case 'billboard':
            return '970x250'
          case 'contentscroller':
            return '893x329'
          case 'contentscroller_small':
            return '688x250'
          case 'halfpage':
            return '300x600'
          case 'rectangle': 
            return '300x250'
          case 'interscroller':
            return '320x500'
          case 'interstitial': 
            return '320x480'
          case 'intersitial_large':
            return '768x1024'
          case 'leaderboard':
            return '728x90'
          case 'midscroller':
            return '320x240'
          case 'mobile_banner':
            return '320x480'
          case 'portrait': 
            return '320x400'
          case 'skyscraper':
            return '120x600'
          case 'wide_skyscraper':
            return '160x600'
          default:
            return '300x250'
        }
      }

      const GetSizeByViewport = function () {
        const viewport = GetViewPort()
        if (viewport > vp_desktop) {
          divid = divid_desktop
          s = document.getElementById(divid_desktop)
          return GetAdSizesByLabel(size_desktop)
        }
        else if (viewport > vp_tablet) {
          divid = divid_tablet
          s = document.getElementById(divid_tablet)
          return GetAdSizesByLabel(size_tablet)
        }
        divid = divid_phone
        s = document.getElementById(divid_phone)
        return GetAdSizesByLabel(size_phone)
      }

      const LoadAd = function () {
        adloaded = true
        const x = new XMLHttpRequest()
        const buster = Math.floor((Math.random() * 100000) + 1)
        let theurlinsert = ''
        try {            
          theurlinsert = '&url=' + encodeURIComponent(window.location.href.toLowerCase().split('?')[0])
        }
        catch (e) {
          //
        }
        const adcall = '//adserving.optoutadvertising.com/hub/display/SterDisplayCookieless.fcgi?adSlot=' + adslot + '&bannerSize=' + adsizes + '&custom[ctchannel]=' + defaultctchannels + '&publisher=1' + theurlinsert + '&cachebuster=' + buster
        x.open('GET', adcall)
        x.responseType = 'text'
        x.onload = function () {
          if (this.status === 200) {
            let toInsert = ''
            toInsert = '<div class="ster-header" style="text-align: center">Advertentie via <a target = "_blank" href="https://www.ster.nl">Ster.nl</a></div>'
            s.innerHTML = toInsert + this.response
            const scripts = s.querySelectorAll("script")
            for (let i = 0; i < scripts.length; i++) {
              const newScript = document.createElement("SCRIPT")
              const responseScript = scripts[i]
              for (let j = 0; j < responseScript.attributes.length; j++) {
                newScript.setAttribute(responseScript.attributes[j].name, responseScript.attributes[j].value)
              }
              responseScript.parentNode.appendChild(newScript)
              responseScript.parentNode.removeChild(responseScript)
            }
          }
          else {
            s.style.height = '0px'
            s.style.visibility = 'hidden'
            s.style.display = 'none'
          }
        }
        x.onerror = function () { }
        x.send()
        adloaded = true
      }

      // Settings
      let adloaded = false
      const divid_phone = 'sterad'
      const divid_tablet = 'sterad'
      const divid_desktop = 'sterad'
      let divid = ''
      const vp_tablet = 600
      const vp_desktop = 1200
      const size_phone = 'portrait'
      const size_tablet = 'leaderboard'
      const size_desktop = 'billboard'
      const adslot = this.place === 'general' ? '_eo_blauwbloed_algemeen_' : '_eo_blauwbloed_artikelpagina_'
      const defaultctchannels = 'royalty_celebrities'
      let s 
      const adsizes = GetSizeByViewport()

      // Init
      const observer = new IntersectionObserver(
        function (entries) {
          for (let i = 0; i < entries.length; i++) {
            if (entries[i].intersectionRatio >= 0.5) {
              if (!adloaded)
                LoadAd()
            }
          }
        },
        {
          root: document.querySelector('#scrollArea'),
          rootMargin: '0px',
          threshold: 0.5,
        },
      )
      const target = document.querySelector('#' + divid)
      observer.observe(target)
    },
  },
}
